/**
* Edit here Angular Material buttons
* Add here global scss
*/

@import "variables";

.mat-raised-button {

    &[ng-reflect-color="primary-inverse"],
    &[color="primary-inverse"] {
        background-color: $rd-primary-inverse !important;
        color: $rd-primary !important;

        &:disabled {
            background-color: rgba($rd-primary-inverse, 0.6) !important;
            color: rgba($rd-primary, 0.6) !important;

            &:hover {
                background-color: rgba($rd-primary-inverse, 0.6) !important;
                color: rgba($rd-primary, 0.6) !important;
            }
        }

        &:hover {
            background-color: darker($rd-primary-inverse, 10%) !important;
        }
    }
}