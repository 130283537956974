/**
* Add here global scss variables
*/

$rd-gradient: linear-gradient(180deg, #1DA7F5 0%, #6B2FEA 101.12%);
$rd-gradient-quem_chegou: linear-gradient(21deg, #FECA39 14.08%, #FF8D00 85.85%);
$rd-primary: #0DA7E2;
$rd-accent: #7D7D7D;
$rd-primary-inverse: #EDF2F5;
$rd-success: #33C51B;
$rd-warning: #FF9D0B;
$rd-error: #ED1010;

$xsm-w-down: 'max-width: 350px';
$sm-w-down: 'max-width: 575px';
$md-w-down: 'max-width: 767px';
$lg-w-down: 'max-width: 991px';
$xl-w-down: 'max-width: 1199px';
$xsm-w-up: 'max-width: 301px';
$sm-w-up: 'min-width: 576px';
$md-w-up: 'min-width: 768px';
$lg-w-up: 'min-width: 992px';
$xl-w-up: 'min-width: 1200px';
$md-h-down: 'max-height: 700px';
$md-h-up: 'min-height: 701px';
