/**
* Edit here Anular Material theme
*/

@import "variables";
@import '~@angular/material/theming';

@include mat-core();

$rd-primary-palette: (50: rgba($rd-primary, 100%),
  100: rgba($rd-primary, 100%),
  200: rgba($rd-primary, 100%),
  300: rgba($rd-primary, 100%),
  400: rgba($rd-primary, 100%),
  500: rgba($rd-primary, 100%),
  600: rgba($rd-primary, 100%),
  700: rgba($rd-primary, 100%),
  800: rgba($rd-primary, 100%),
  900: rgba($rd-primary, 100%),
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ));

$rd-accent-palette: (50: rgba($rd-accent, 100%),
  100: rgba($rd-accent, 100%),
  200: rgba($rd-accent, 100%),
  300: rgba($rd-accent, 100%),
  400: rgba($rd-accent, 100%),
  500: rgba($rd-accent, 100%),
  600: rgba($rd-accent, 100%),
  700: rgba($rd-accent, 100%),
  800: rgba($rd-accent, 100%),
  900: rgba($rd-accent, 100%),
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ));

$rd-error-palette: (50: rgba($rd-error, 100%),
  100: rgba($rd-error, 100%),
  200: rgba($rd-error, 100%),
  300: rgba($rd-error, 100%),
  400: rgba($rd-error, 100%),
  500: rgba($rd-error, 100%),
  600: rgba($rd-error, 100%),
  700: rgba($rd-error, 100%),
  800: rgba($rd-error, 100%),
  900: rgba($rd-error, 100%),
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ));

$rd-primary: mat-palette($rd-primary-palette);
$rd-accent: mat-palette($rd-accent-palette);
$rd-warn: mat-palette($rd-error-palette);
$rd-theme: mat-light-theme((color: (primary: $rd-primary,
      accent: $rd-accent,
      warn: $rd-warn,
    )));

@include angular-material-theme($rd-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
