/**
* Import here other scss files
* Add here global scss
*/

@import "theme";
@import "mixins";
@import "variables";
@import "material/buttons.scss";

* {
    font-family: "arial";
}

html,
body {
    -webkit-text-size-adjust: none;
    height: 100%;
}

html[lang="pt"] {
    .anonimous-minimalistic-layout {
        background: $rd-gradient-quem_chegou;
    }
}
